import React from "react"
import { Accordion, Button, Card, Badge, Container } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MandatoryDetails = () => (
  <React.Fragment>
  <Layout pageInfo={{ pageName: "Mandatory Details - NCTE" }}>
    <SEO title="Mandatory Details - NCTE" />
    <Container>
      <Accordion>
        <Accordion.Item eventKey="1111">
          <Accordion.Header>Course details</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/CourseDetails.pdf">
                  <Button variant="outline-dark">
                      Course Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="22231">
          <Accordion.Header>Faculty details</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/FacultyDetails.pdf">
                  <Button variant="outline-dark">
                      Faculty Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5533">
          <Accordion.Header>Attendance details</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/AttendanceDetails2019-21.pdf">
                  <Button variant="outline-dark">
                    Attendance Details 2019-21
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <br />
                <p>
                    <a href="../documents/MandatoryDetails/AttendanceDetails2020-22.pdf">
                        <Button variant="outline-dark">
                            Attendance Details 2020-22
                            <Badge variant="secondary">PDF</Badge>
                        </Button>
                    </a>
                </p>

              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="44536">
          <Accordion.Header>Infrastructure Details  </Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/InfraFacilities.pdf">
                  <Button variant="outline-dark">
                      Infrastructure Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="565635">
          <Accordion.Header>Fee Details</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/CourseDetails.pdf">
                  <Button variant="outline-dark">
                      Fee Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1325346">
          <Accordion.Header>Instructional Facilities Details</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/InstructionalFacilities.pdf">
                  <Button variant="outline-dark">
                      Instructional Facilities Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1463463">
          <Accordion.Header>Library</Accordion.Header>
          <Accordion.Body>
            
              <p>
                <a href="../documents/MandatoryDetails/Library.pdf">
                  <Button variant="outline-dark">
                      Library Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="134633">
          <Accordion.Header>Balance Sheet and Funding Details</Accordion.Header>
          <Accordion.Body>
            
          <p>
                <a href="../documents/MandatoryDetails/Balancesheet2021-22.pdf">
                  <Button variant="outline-dark">
                      Balance Sheet 21-22
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Balancesheet2022-23.pdf">
                  <Button variant="outline-dark">
                      Balance Sheet 22-23
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/BalanceSheet.pdf">
                  <Button variant="outline-dark">
                      Balance Sheet 
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
              <p>
                <a href="../documents/MandatoryDetails/EndowmentFundB.Ed.78.pdf">
                  <Button variant="outline-dark">
                  Endowment Fund (B.Ed.)     
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
              <p>
                <a href="../documents/MandatoryDetails/EndowmentFundB.Ed.79.pdf">
                  <Button variant="outline-dark">
Endowment Fund (B.Ed.)                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
              <p>
                <a href="../documents/MandatoryDetails/EndowmentFundM.Ed.pdf">
                  <Button variant="outline-dark">
Endowment Fund (M.Ed.)                 <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              
              <p>
                <a href="../documents/MandatoryDetails/ReservedFundB.Ed.80.pdf">
                  <Button variant="outline-dark">
                  Reserved Fund (B.Ed.) 
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/ReservedFundB.Ed.81.pdf">
                  <Button variant="outline-dark">
                  Reserved Fund (B.Ed.)      
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/ReservedFundM.Ed.pdf">
                  <Button variant="outline-dark">
                Reserved Fund (M.Ed.)     
              <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/IncomeAndExpeditureAccount.pdf">
                  <Button variant="outline-dark">
                      Income and Expenditure Account Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Incomeandexpenditure2021-22.pdf">
                  <Button variant="outline-dark">
                      Income and Expenditure Account Details 21-22
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Incomeandexpenditure2022-23.pdf">
                  <Button variant="outline-dark">
                      Income and Expenditure Account Details 22-23
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/ReceiveAndPaymentAccount.pdf">
                  <Button variant="outline-dark">
                  Receive and Payment Account Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Receiptandpayment2021-22.pdf">
                  <Button variant="outline-dark">
                  Receive and Payment Account Details 21-22
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Receiptandpayment2022-23.pdf">
                  <Button variant="outline-dark">
                  Receive and Payment Account Details 22-23
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="243634634">
          <Accordion.Header>Student Details</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/MandatoryDetails/B.Ed.StudentsDetails2019-21.pdf">
                  <Button variant="outline-dark">
                    Student Details (B.Ed.) 2019-21 <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/B.Ed.StudentsDetails2020-22.pdf">
                  <Button variant="outline-dark">
                    Student Details (B.Ed.) 2020-22     <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/B.Ed.2021-23Details.pdf">
                  <Button variant="outline-dark">
                    Student Details (B.Ed.) 2021-23     <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/B.Ed.2022-24Registration.pdf">
                  <Button variant="outline-dark">
                    Student Details (B.Ed.) 2022-24     <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/B.Ed.2023-25RegistrationFinal.pdf">
                  <Button variant="outline-dark">
                    Student Details (B.Ed.) 2023-25     <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
          </Accordion.Body>
        </Accordion.Item>
       
        <Accordion.Item eventKey="2436346">
          <Accordion.Header>NCTE Recognition Letter</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/MandatoryDetails/NCTE-RecognitionLetter.pdf">
                  <Button variant="outline-dark">
                  NCTE - Recognition Letter   
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
            
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2435157">
          <Accordion.Header>Facilities added last quarter</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/MandatoryDetails/FacilitiesAddedLastQ.pdf">
                  <Button variant="outline-dark">
                 Facilities added last quarter
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
            
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4453">
          <Accordion.Header>Fire Safety Details</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/MandatoryDetails/FireSafetyCert.pdf">
                  <Button variant="outline-dark">
                  Fire Safety Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
            <p>
                <a href="../documents/MandatoryDetails/FireSafetyCertificate2022.pdf">
                  <Button variant="outline-dark">
                  Fire Safety Details
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>

          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="54464">
          <Accordion.Header>Facilities</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/MandatoryDetails/Biometric.jpeg">
                  <Button variant="outline-dark">
                  Biometric Attendance System
                   <Badge variant="secondary">JPEG</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/WaterPurifier.jpeg">
                  <Button variant="outline-dark">
Water Purification System                   <Badge variant="secondary">JPEG</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/FireEquipment.jpeg">
                  <Button variant="outline-dark">
                  Fire Safety Equipments
                   <Badge variant="secondary">JPEG</Badge>
                  </Button>
                </a>
              </p>
            
            
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="54363214">
          <Accordion.Header>PAR Affidavit</Accordion.Header>
          <Accordion.Body>
           
              <p>
                <a href="../documents/MandatoryDetails/PAR_Affidavit.pdf">
                  <Button variant="outline-dark">
                  PAR Affidavit
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Affidavie2021-22.pdf">
                  <Button variant="outline-dark">
                  PAR Affidavit 2021-22
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
              <p>
                <a href="../documents/MandatoryDetails/Affidavite2022-23.pdf">
                  <Button variant="outline-dark">
                  PAR Affidavit 2022-23
                   <Badge variant="secondary">PDF</Badge>
                  </Button>
                </a>
              </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
        
     
      <br />
      <br />
    </Container>
  </Layout>
  </React.Fragment>
)

export default MandatoryDetails
